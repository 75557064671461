import React from "react";
import {ReceipeContainer, ReceipeH1, ReceipeWrapper, ReceipeCard, ReceipeIcon, ReceipeH2, ReceipeP} from "./ReceipeElements";
//import Icon2 from "../../images/online.svg"
//import Icon3 from "../../images/real-time.svg"
import Icon2 from "../../images/Turmeric Powder.PNG"
import Icon3 from "../../images/Chilly Powder.PNG"
import Icon4 from "../../images/Coriander Powder.PNG"
import Icon5 from "../../images/Garam Masala.PNG"

const Receipe = () => {
  return (
    <ReceipeContainer id="Receipe">
      <ReceipeH1>Recipe that is Creativity </ReceipeH1>
      <ReceipeWrapper>
        <ReceipeCard>
          <ReceipeIcon src={Icon3} />
          <ReceipeH2>Receipe 1</ReceipeH2>
          <ReceipeP>
          Suddha Sattva Red Hot Chilli Powder is crafted meticulously, preserving flavor and color. 
          Hand-picked chilies are sun-dried and processed scientifically for high-quality, 
          hygienically packaged freshness.
          </ReceipeP>
        </ReceipeCard>
        <ReceipeCard>
          <ReceipeIcon src={Icon2} />
          <ReceipeH2>Recipe 2</ReceipeH2>
          <ReceipeP>
          Suddhasattva Turmeric Powder, crafted from premium Selam turmeric fingers, 
          not only enhances the flavor, color, and aroma of your dishes 
          but also provides balanced nutrition with its rich and nourishing contents.
          </ReceipeP>
        </ReceipeCard>
        <ReceipeCard>
          <ReceipeIcon src={Icon4} />
          <ReceipeH2>Recipe 3</ReceipeH2>
          <ReceipeP>
          Suddhasattva Coriander Powder is crafted from premium coriander seeds, cleansed, dried, roasted, 
          and finely ground to enhance flavor, color, and aroma in your cooking while retaining nourishing qualities.
          </ReceipeP>
        </ReceipeCard>
        <ReceipeCard>
          <ReceipeIcon src={Icon4} />
          <ReceipeH2>Recipe 4</ReceipeH2>
          <ReceipeP>
          Suddha Sattva Cumin Powder is made from premium cumin seeds that are meticulously cleaned, naturally dried, roasted to perfection, 
          and finely ground to enhance flavor and aroma in your cooking.
          </ReceipeP>
        </ReceipeCard>
        <ReceipeCard>
          <ReceipeIcon src={Icon5} />
          <ReceipeH2>Recipe 5</ReceipeH2>
          <ReceipeP>
          Suddha Sattva Garam Masala is a blend of premium spices meticulously selected and ground to perfection. 
          Its rich aroma and balanced flavors elevate your dishes to a new level of culinary delight.
          </ReceipeP>
        </ReceipeCard>
        <ReceipeCard>
          <ReceipeIcon src={Icon3} />
          <ReceipeH2>Recipe 6</ReceipeH2>
          <ReceipeP>
          Suddha Sattva Mild Chilli Powder: Carefully selected premium mild chilies retain vibrant color while adding a subtle kick to dishes, 
          ideal for those with milder spice preferences seeking authentic Indian flavors.
          </ReceipeP>
        </ReceipeCard>
      </ReceipeWrapper>
    </ReceipeContainer>
  );
};

export default Receipe;