import React from "react";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from "./ServicesElements";
//import Icon2 from "../../images/online.svg"
//import Icon3 from "../../images/real-time.svg"
import Icon2 from "../../images/Turmeric Powder.PNG"
import Icon3 from "../../images/Chilly Powder.PNG"
import Icon4 from "../../images/Coriander Powder.PNG"
import Icon5 from "../../images/Garam Masala.PNG"

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Products</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Chilli Powder(Hot)</ServicesH2>
          <ServicesP>
          Suddha Sattva Red Hot Chilli Powder is crafted meticulously, preserving flavor and color. 
          Hand-picked chilies are sun-dried and processed scientifically for high-quality, 
          hygienically packaged freshness.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Turmeric Powder</ServicesH2>
          <ServicesP>
          Suddhasattva Turmeric Powder, crafted from premium Selam turmeric fingers, 
          not only enhances the flavor, color, and aroma of your dishes 
          but also provides balanced nutrition with its rich and nourishing contents.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Coriander Powder</ServicesH2>
          <ServicesP>
          Suddhasattva Coriander Powder is crafted from premium coriander seeds, cleansed, dried, roasted, 
          and finely ground to enhance flavor, color, and aroma in your cooking while retaining nourishing qualities.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Cumin Powder</ServicesH2>
          <ServicesP>
          Suddha Sattva Cumin Powder is made from premium cumin seeds that are meticulously cleaned, naturally dried, roasted to perfection, 
          and finely ground to enhance flavor and aroma in your cooking.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>Garam Masala</ServicesH2>
          <ServicesP>
          Suddha Sattva Garam Masala is a blend of premium spices meticulously selected and ground to perfection. 
          Its rich aroma and balanced flavors elevate your dishes to a new level of culinary delight.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Chilly Powder(Mild)</ServicesH2>
          <ServicesP>
          Suddha Sattva Mild Chilli Powder: Carefully selected premium mild chilies retain vibrant color while adding a subtle kick to dishes, 
          ideal for those with milder spice preferences seeking authentic Indian flavors.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
