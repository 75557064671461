import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";

//import Image1 from "../images/design-notes.svg";
import Image1 from "../images/DLK_6875.jpg";
import Image2 from "../images/Certificate.JPG";
import Image3 from "../images/Suddha Sattva Logo.png";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";
import ContactUs from "../components/ContactUs";
import Receipe from "../components/Receipe";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection
        image={Image2}
        id="about"
        //subtitle="Welcome to wasnik agrotech private limited"
        title="Who are we!"
        text="Welcome to the vibrant world of Wasnik Agrotech, located in Pune, Maharashtra. We specialize in premium organic Indian spices, carefully crafted to blend authenticity, purity, and passion. 
        Founded by two visionary sisters-in-law, our company celebrates entrepreneurship driven by women. 
        
        We are proud to be recognized by Startup India for our innovative approach and dedication to quality. 
        Join us on an aromatic journey where tradition meets innovation, and each flavor narrates a story of dedication and excellence."
        btnText="More Information"
      />
      <InfoSectionLight
        image={Image3}
        id="discover"
        //subtitle="Welcome to Suddha Sattva - Experience the Purity"
        title="Suddha Sattva"
        text="At Suddha Sattva, we believe in harnessing the purest elements of nature to promote holistic well-being. Our brand is a testament to the timeless connection between mind, body, and spirit. Guided by ancient wisdom and driven by modern innovation, Suddha Sattva is your companion on the journey to a balanced and vibrant life."
        btnText="Explore"
      />
      <Services />
      <InfoSectionLight
        image={Image1}
        id="#"
        //subtitle="Duis et lectus accumsan"
        title="About Us"
        text="Aruna Wasnik and Jaishree Wasnik are the dynamic founders of Wasnik Agrotech, Pune's premier destination for premium organic Indian spices. Aruna, with a master's degree in Geography & Sociology, brings a deep understanding of culture and tradition to our spice creations. Jaishree, armed with a Bachelor's in Science and an MBA in Retail, brings a unique blend of scientific knowledge and business acumen to our company. Together, their passion and expertise drive our commitment to quality, authenticity, and innovation in every spice we offer."
        btnText="Read more"
      />
      <Receipe/>
      <ContactUs
        image={Image1}
        id="#"
        //subtitle="Duis et lectus accumsan"
        title="Contact Us"
        text="Aruna Wasnik and Jaishree Wasnik are the dynamic founders of Wasnik Agrotech, Pune's premier destination for premium organic Indian spices. Aruna, with a master's degree in Geography & Sociology, brings a deep understanding of culture and tradition to our spice creations. Jaishree, armed with a Bachelor's in Science and an MBA in Retail, brings a unique blend of scientific knowledge and business acumen to our company. Together, their passion and expertise drive our commitment to quality, authenticity, and innovation in every spice we offer."
        btnText="Read more"
      />
      <Footer />
    </>
  );
};
